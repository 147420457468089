import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Grid, Row, Col, Button, Modal } from 'react-bootstrap'
const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    loading: false,
    submitted: false,
    errors: []
};
const defaultSuccessMessage = "Great work! Your friend will shortly receive an email invite to sign up.";
const defaultErrorMessage = "There was a problem sending your invite, please try again.";
class FriendInviteModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.firstNameInput = React.createRef();
    this.lastNameInput  = React.createRef();
    this.emailInput     = React.createRef();
  }
  hasError = (key) => {
    return this.state.errors.indexOf(key) !== -1;
  }
  handleInputChange = (event) => {
    var key = event.target.name;
    var value = event.target.value;
    var obj = {};
    obj[key] = value;
    this.setState(obj, () => {
      this.validate(false);
    });
  }
  reset = () => {
    this.setState({show: false, showResponseModal: false}, () => {
      this.setState(initialState);
    });
  }
  close = () => {
    this.setState({show: false})
  }
  handleSubmit = (event) => {
    event.preventDefault();
    var errors = this.validate(true);
    this.setState({
      errors: errors
    }, () => {
        if (errors.length > 0) {
          this.focusToError();
          this.setState({submitted: true});
        } else {
          this.setState({loading: true});
          // API Call
          this.processRequest(this.state.email, this.state.firstName, this.state.lastName);
        }
      }
    );
  }
  validate = (submit) => {
    if (!this.state.submitted && !submit) return;
    //VALIDATE
    var errors = [];
    //firstname
    if (this.state.firstName === "") {
      errors.push("firstname");
    }
    //lastname
    if (this.state.lastName === "") {
      errors.push("lastname");
    }
    //email
    const expression = /.+@.+\..+/;
    var validEmail = expression.test(String(this.state.email).toLowerCase());
    if (!validEmail) {
      errors.push("email");
    }
    this.setState({
      errors: errors
    });
    return errors
  }
  showForm = (show) => {
    this.setState(initialState);
    this.setState({showResponseModal: !show, loading: false});
  }
  processRequest = (email, firstName, lastName) => {
    return fetch(`/notifications/refer_a_friend`,
    {
      method: "POST",
      cache:  "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.props.token
      },
      body: JSON.stringify({email: email, first_name: firstName, last_name: lastName})
    })
    .then((response) => {
      if (response.ok) {
        var data = {error: ""};
        return data;
      } else {
        return response.json();
      }
    })
    .then((data) => {
      var success = true;
      var message = "";
      if (data.error != ""){
        success = false;
        message = data.error;
      }
      this.setState({success: success, message: message},
        () => {
          this.showForm(false);
        }
      );
    })
    .catch((error) => {
      this.setState({success: false, message: ""},
        () => {
          this.showForm(false);
        }
      );
    });
  }
  handleShow = () => {
    this.reset();
    this.setState({ show: true });
  }
  handleClose = () => {
    if (!this.state.loading) {
      this.setState({show: false})
    }
  }
  showErrorMessage = (message) => {
    return <div className="parsley-custom-error-message">{message}</div>;
  }
  focusToError = () => {
    if (this.hasError('firstname')) {
      this.firstNameInput.current.focus();
    } else if (this.hasError('lastname')){
      this.lastNameInput.current.focus();
    } else {
      this.emailInput.current.focus();
    }
  }
  getMessage = () => {
    if (this.state.success) {
      return defaultSuccessMessage
    } else {
      if (this.state.message == "") {
        return defaultErrorMessage;
      } else {
        return this.state.message;
      }
    }
  }
  inviteAnotherFriend = () => {
    this.showForm(true);
  }
  componentDidMount = () => {
    this.setState({success: false, message: "Something went wrong", showResponseModal: false });
  }

  customText = () => {
    if (this.props.invitingFriendsCustomText) {
      return this.props.invitingFriendsCustomText;
    }
    return "Invite a friend to join the challenge. Enter your friends details below and send them an invite.";
  }

  render = () => {
    var formBody =
      <div>
        <div className="row">
          <div className="col-sm-12">
            <h3 className="text-center">Invite a friend</h3>
            <div className="text-center">
              { this.customText() }
            </div>
          </div>
        </div>
        <form className="row mobile-optimized-form" id="invite-friend-form">
          <fieldset disabled={this.state.loading}>
            <div className={`
              col-lg-12
              form-group
              required
              ${!this.hasError('firstname') && this.state.errors.length>0 ? "has-success" : ""}
              ${this.hasError('firstname') ? 'has-error' : ''}
              `}>
              <label
                className={`
                    control-label required
                    ${this.hasError('firstname') ? 'has-error' : ''}
                  `}
                  htmlFor="firstName">
                  Friend's First Name
                  <abbr title="required">*</abbr>
                </label>
              <input
                autoComplete="off"
                className={
                  this.hasError("firstname")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleInputChange}
                ref={this.firstNameInput}
                maxLength="30"
              />
              <span className="help-block filled" id="parsley-id-5" aria-hidden={!this.hasError('firstname')}>
                {this.hasError('firstname') ? this.showErrorMessage('Please provide a first name') : ''}
              </span>
            </div>
            <div className={`
              col-lg-12
              form-group
              required
              ${!this.hasError('lastname') && this.state.errors.length>0 ? "has-success" : ""}
              ${this.hasError('lastname') ? 'has-error' : ''}
              `}>
              <label
                className={`
                  control-label required
                  ${this.hasError('lastname') ? 'has-error' : ''}
                `}
                htmlFor="lastName">
                  Friend's Last Name
                  <abbr title="required">*</abbr>
                </label>
              <input
                autoComplete="off"
                className={
                  this.hasError("lastname")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleInputChange}
                ref={this.lastNameInput}
                maxLength="30"
              />
              <span className="help-block filled" id="parsley-id-5" aria-hidden={!this.hasError('lastname')}>
                {this.hasError('lastname') ? this.showErrorMessage('Please provide a last name') : ''}
              </span>
            </div>
            <div className={`
                col-lg-12
                form-group
                required
                ${!this.hasError('email') && this.state.errors.length>0 ? "has-success" : ""}
                ${this.hasError('email') ? 'has-error' : ''}
              `}>
              <label
                className={`
                  control-label required
                  ${this.hasError('email') ? 'has-error' : ''}
                `}
                htmlFor="email">
                  Friend's Email
                  <abbr title="required">*</abbr>
                </label>
              <input
                autoComplete="off"
                className={
                  this.hasError("email")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                ref={this.emailInput}
                maxLength="50"
              />
              <span className="help-block filled" id="parsley-id-5" aria-hidden={!this.hasError('email')}>
                {this.hasError('email') ? this.showErrorMessage('Please provide a valid e-mail') : ''}
              </span>
            </div>
            <div className="col-lg-12  padd-top">
              <button className="btn btn-primary btn-lg btn-block submit-disable rounded-lg p-l-2 pr-5 position-relative" onClick={this.handleSubmit} disabled={this.state.loading}>
                <span
                  className={
                    this.state.loading
                      ? "spinner-border spinner-border-sm"
                      : "hidden"
                    }
                  role="status" aria-hidden="true"/>
                <span
                  className={
                    this.state.loading
                      ? ""
                      : "hidden"
                    }>
                      Sending...
                </span>
                <span className={this.state.loading ? "hidden" : "" }> Send Invite </span>
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    var responseBody =
    <div>
      <div className="row">
        <div className="col-sm-12">
          <h3 className="text-center">{this.state.success ? "Invite sent" : "Something went wrong"}</h3>
          <div className="text-center">
            {this.getMessage()}
          </div>
        </div>
      </div>
      <div className="padded-image-container">
        <img src={this.state.success ? this.props.successImg : this.props.errorImg} className="img-fluid"/>
      </div>
      <div className="row">
        <div className="col-sm-12 invite-another-button">
          <button className="btn btn-primary btn-lg btn-block submit-disable rounded-lg p-l-2 pr-5 position-relative" onClick={this.inviteAnotherFriend}>
            <span> { this.state.success ? "Invite another friend" : "Try again"} </span>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 close-link">
          <a role="button" onClick={this.close}>Close</a>
        </div>
      </div>
    </div>
    return (
      <div>
        <Modal id="invite-friend"
        className="modal small-modal fade in"
        show={this.state.show} onHide={this.handleClose} backdrop={!this.state.loading} keyboard={!this.state.loading}>
          <Modal.Header closeButton/>
          <Modal.Body className="text-center">
            { this.state.showResponseModal ? responseBody : formBody }
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default FriendInviteModal
