import React, { useState, useEffect } from 'react';
import Fundraising from './Fundraising';

const FundraisingHistory = (props) => {
  const displayLimit = 3;

  const fundraisingHistory = props.fundraisingHistory.map(fundraising =>
    <Fundraising key={ fundraising.table.id } data={ fundraising.table } />
  );

  const showMore = () => {
    const text = $('.load-more span').text();
    if (text === 'SHOW MORE') {
      var fundraisings = document.getElementsByClassName('fundraising');
      for (let element of fundraisings) {
        element.style.display = 'block';
      };
      $('.load-more span').text('SHOW LESS');
    } else {
      var fundraisings = document.getElementsByClassName('fundraising');
      for (let i = displayLimit; i < fundraisings.length; i++) {
        fundraisings[i].style.display = 'none';
      }

      $('.load-more span').text('SHOW MORE');
    }
  };

  let loadMore;
  if (fundraisingHistory.length > displayLimit) {
    loadMore = (
      <div id='load-more' className='load-more' onClick={ showMore }><span>SHOW MORE</span></div>
    );
  }

  return (
    <section className='panel panel-default fundraising-history'>
      <div className='panel-heading'>
        <h2 className='panel-title'>Funds Raised Over The Years</h2>
        <hr/>
      </div>
      <div className='panel-body'>
        {
          fundraisingHistory
        }
      </div>
      { loadMore }
      <div>&nbsp;</div>
    </section>
  )
};

export default FundraisingHistory;