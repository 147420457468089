import React, { useState, useEffect } from 'react';

const Fundraising = (props) => {
  return (
    <>
      <div className='fundraising row gutters-xs'>
        <div className='background'>
          <div className='year'>{ props.data.year }</div>
          <div className='border'>
            <div className='amount'>{ props.data.currency_symbol }</div>
            <i className='fa fa-trophy'></i>
            <div className='raised'>Raised</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Fundraising;