import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import { Label } from 'react-bootstrap';
import SelectSearch from 'react-select-search';

import "react-select-search/style.css";
import CountryData from './service_areas/country_codes.json'

class ServiceAreas extends Component {
  constructor(props) {
    super(props)
  
    this.state = {selectedAreas: []}
  
    const countries = this.countryList().filter(country => {
      return props.selectedAreas.includes(country.value)
    })
  
    const continents = this.continentList().filter(continent => {
      return props.selectedAreas.includes(continent.value)
    })
  
    this.state = {selectedAreas: continents.concat(countries)}
  }
  
  countryList = () => (
    CountryData.map(country => ({ name: country['country-name'], value: country.iso3 }))
  )
  
  continentList = () => (
    [
      {name: 'Africa', value: 'AF'},
      {name: 'Antarctica', value: 'AN'},
      {name: 'Asia', value: 'AS'},
      {name: 'Europe', value: 'EU'},
      {name: 'North American', value: 'NA'},
      {name: 'Oceania', value: 'OC'},
      {name: 'South American', value: 'SA'}
    ]
  )

  addArea = (option) => {
    console.log(option)
    if (this.state.selectedAreas.map((area) => (area.name)).includes(option.name)) {
      return null
    }

    if (option.groupName == 'Continents') {
      this.setState({
        selectedAreas: this.state.selectedAreas.concat([{name: option.name, value: option.value}])
      })
    }
  
    if (option.groupName == 'Countries') {
      this.setState({
        selectedAreas: this.state.selectedAreas.concat([{name: option.name, value: option.value}])
      })
    }
  }
  
  removeArea = (code) => {
    this.setState({
      selectedAreas: this.state.selectedAreas.filter(
          area => area.value !== code),
    })
  }

  renderItems = () => {
    return (
        this.state.selectedAreas.map((area) => {
          if (area['value'].length == 2) {
            return (
              <Label key={area['name']} bsStyle="primary" style={{cursor: 'pointer'}} onClick={() => this.removeArea(area['value'])}>
                {area['name']}
                <input
                  type="hidden"
                  name="campaign[geolocation_attributes][][code]"
                  value={area['value']} />
              </Label>
            )
          }

          if (area['value'].length == 3) {
            return (
              <Label key={area['name']} bsStyle="success" style={{cursor: 'pointer'}} onClick={() => this.removeArea(area['value'])}>
                {area['name']}
                <input
                    type="hidden"
                    name="campaign[geolocation_attributes][][code]"
                    value={area['value']} />
              </Label>
            )
          }
        })
    )
  }

  handleChange = (code, item) => {
    this.addArea(item)
  }

  render = () => {
    
    const options = [
      {
        type: 'group',
        name: 'Continents',
        items: this.continentList()
      },
      {
        type: 'group',
        name: 'Countries',
        items: this.countryList()
      },
    ];

    const handleFilter = (items) => {
      return (searchValue) => {
        if (searchValue.length === 0) {
          return options;
        }
        const updatedItems = items.map((list) => {
          const newItems = list.items.filter((item) => {
            return item.name.toLowerCase().includes(searchValue.toLowerCase());
          });
          return { ...list, items: newItems };
        });
        return updatedItems;
      };
    };

    return (
        <div>
          <div className="row">
            <div className="col-xs-12">
              { this.renderItems() }
            </div>
          </div>
          <SelectSearch
            options={options}
            name="Area"
            search
            filterOptions={handleFilter}
            onChange={this.handleChange}
            placeholder="Choose Services Areas of this Campaign" />
        </div>
    )
  }
}

export default ServiceAreas
