// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { Component } from 'react';

import QRCode from 'qrcode.react'
import { Modal } from 'react-bootstrap';
const DownloadIcon = 'https://res.cloudinary.com/chil/image/upload/v1629789466/ic-file-download.svg'

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: window.ezyraise.currentUser,
    }
  }
  
  downloadQRCode = () => {
    const svg = document.querySelector( "#svg-qr-code" );
    const svgData = new XMLSerializer().serializeToString( svg );
  
    const canvas = document.createElement( "canvas" );
    const ctx = canvas.getContext( "2d" );
    ctx.canvas.width  = 160;
    ctx.canvas.height  = 160;
  
    let img = document.createElement( "img" );
    img.setAttribute( "src", "data:image/svg+xml;base64," + btoa( svgData ) );
    
    img.onload = function() {
      ctx.drawImage( img, 2, 2 );
  
      const w = window.open("");
      w.document.write(img.outerHTML);
    };
  }

  downloadLinkStyle = ()=> {
    let textColor = '#333333';
    if (this.props.textColor.match(/\#[a-zA-Z0-9]{6}/)) {
      textColor = this.props.textColor;
    }
    return {
      marginTop: 25,
      color: textColor,
      fill: textColor,
      fontSize: 15,
      fontFamily: 'Helvetica Bold',
      cursor: 'pointer'
    }
  }

  showQRWallpaper = () => {
    if (this.state.user.qr_wallpaper_url_enabled && this.state.user.qr_wallpaper_url) {
      return true;
    }
    return false;
  }

  render = ()=> {
    const modalStyle = {
      marginTop: 30,
      paddingTop: 15,
      borderRadius: 5
    };
    
    const avatarStyle = {
      marginTop: -50
    };
    
    const userNameStyle = {
      fontFamily: 'Helvetica Bold'
    };
    
    const avatarImgStyle = {
      verticalAlign: 'middle',
      width: 96,
      height: 96,
      borderRadius: '50%',
      marginLeft: '12%',
      border: '#ffffff solid 5px',
      backgroundColor: '#FFFFFF'
    };
    
    const closeButton = {
      cursor: 'pointer'
    }
    
    const xlinkHref = DownloadIcon + '#circleIcon'
    
    return (
      <Modal show={this.props.isModalVisible} style={modalStyle} onHide={this.props.onClose} bsSize="small">
        <Modal.Body>
          <button className="close" data-dismiss="modal" type="button" onClick={this.props.onClose}>
            <span aria-hidden="true">×</span>
          </button>
          <div className="profile-avatar" style={avatarStyle}>
            <img style={avatarImgStyle} src={this.props.avatarUrl} />
          </div>
          <div style={{textAlign: 'center', marginTop: -35}} >
            <h4 style={userNameStyle} >{this.props.name}</h4>
            <p style={{marginBottom: 35}} >
              Scan this QR code to donate to {this.props.name}
            </p>
            <QRCode
              id={'svg-qr-code'}
              size={156} value={this.props.shareUrl}
              renderAs={'svg'} />
          </div>
          {
            this.showQRWallpaper() &&
            <a className="btn btn-primary btn-lg btn-block" style={{marginTop: '10px'}} onClick={ () => window.open(this.state.user.qr_wallpaper_url, '_blank') }>Download QR Code</a>
          }
          {
            !this.showQRWallpaper() &&
            <div style={{height: 40}}></div>
          }
        </Modal.Body>
      </Modal>
    )
  }
}

export default Popup;
