import React from 'react'
import { mount, shallow } from 'enzyme'
import ProfileWelcomePanel from '../ProfileWelcomePanel'
import { Button } from 'react-bootstrap/lib/InputGroup'

describe('Buttons', () => {
  const setHrefSpy = jest.fn(href => href)

  beforeAll(() => {
    delete window.location
    window.location = {}
    window.ezyraise = {
      currentUser: {
        first_name: 'john',
        last_name: 'Smith'
      }
    }
    Object.defineProperty(window.location, 'href', {
      set: setHrefSpy,
    })
  })

  afterAll(() => {
    window.location = location
    delete window.ezyraise
  })

  test('it has Spread The Word Button', () => {
    const wrapper = mount(<ProfileWelcomePanel data={ {token: 'token', success_img: 'img1', error_img: 'img2'} } />)
    expect(wrapper.text().includes('Spread The Word')).toBe(true)
  })

  describe('Spread The Word', () => {
    test('it changes window.location.href', () => {
      const wrapper = mount(<ProfileWelcomePanel data={ {token: 'token', success_img: 'img1', error_img: 'img2'} } />)
      const spreadTheWordButton = wrapper.findWhere(node => {
        return (
          node.type() === 'button' &&
          node.name() &&
          node.text() === 'Spread The Word'
        )
      }).at(0)

      spreadTheWordButton.simulate('click')
      expect(setHrefSpy).toHaveBeenCalled()
    })
  })
})