// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { Component } from 'react'
import ReactDOM from 'react-dom'

const qrCodeIcon = 'https://res.cloudinary.com/chil/image/upload/v1629789525/profile_qr_code_icon.svg'
import Popup from './profile_qrcode/Popup'

class ProfileQrcode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false
    }
  }
  
  handleOpen = () => {
    this.setState({
      isModalVisible: true
    })
  }
  
  handleClose = () => {
    this.setState({
      isModalVisible: false
    })
  }

  showQRWallpaper = () => {
    if (this.state.user.qr_wallpaper_url_enabled && this.state.user.qr_wallpaper_url) {
      return true;
    }
    return false;
  }
  
  render = () => {
    const iconStyle = {
      padding: 4,
      backgroundColor: '#dddddd',
      borderRadius: 4
    }
    
    return (
      <div>
        <a onClick={ this.handleOpen } style={{cursor: 'pointer'}}>
          <img src={qrCodeIcon} style={iconStyle} />
        </a>
        <Popup
          onClose={this.handleClose}
          isModalVisible={this.state.isModalVisible}
          avatarUrl={this.props.qrcodeComponentData.avatar_url}
          name={this.props.qrcodeComponentData.name}
          shareUrl={this.props.qrcodeComponentData.share_url}
          textColor={this.props.qrcodeComponentData.text_color}
        />
      </div>
    )
  }
}

export default ProfileQrcode
